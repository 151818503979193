import { IUploadedFileData } from 'interfaces'
import { TNormalizeImageFileProps } from 'services/AppService'
import { AppService, SimService } from 'services'
import { getFileTypeByMimeType } from 'utils/helpers'

export type TFile = {
    file: File
    fileData?: IUploadedFileData
    image?: HTMLImageElement
    imageBase64?: string
}

type TImageFileParams = {
    isNormalizeImageFile?: boolean
    imageParams?: TNormalizeImageFileProps
}

type TUploadFilesParams = {
    isUpload?: boolean
} & TImageFileParams

/**
 * Upload file on server or get base64 upload file data
 */
export default function useUploadFiles() {
    const upload = (fileList: FileList, { isUpload = true, ...params }: TUploadFilesParams = {}) => {
        const files = Array.from(fileList)
        const requests = isUpload
            ? files.map<Promise<TFile>>((file) => uploadFile(file, params))
            : files.map<Promise<TFile>>((file) => getFile(file, params))

        return Promise.all(requests)
    }

    function normalizeImageFile(file: File, params?: TNormalizeImageFileProps) {
        return AppService.normalizeImageFile(file, params)
    }

    function getImageBase64(file: File) {
        return AppService.fileToBase64(file)
    }

    function getFile(file: File, { isNormalizeImageFile = true, imageParams }: TImageFileParams) {
        if (isNormalizeImageFile) {
            const { type: fileType } = getFileTypeByMimeType(file.type)

            if (fileType === 'image') {
                let imageFile: File
                let image: HTMLImageElement

                return normalizeImageFile(file, imageParams)
                    .then((data) => {
                        imageFile = data.file
                        image = data.image

                        return getImageBase64(data.file)
                    })
                    .then((imageBase64) => {
                        return { file: imageFile, image, imageBase64 }
                    })
                    .catch(() => {
                        return Promise.reject()
                    })
            }
        }

        return Promise.resolve({ file, image: undefined, imageBase64: undefined })
    }

    function uploadFile(file: File, { isNormalizeImageFile = true, imageParams }: TImageFileParams) {
        if (isNormalizeImageFile) {
            const { type: fileType } = getFileTypeByMimeType(file.type)

            if (fileType === 'image') {
                let imageFile: File
                let image: HTMLImageElement
                let imageBase64: string

                return normalizeImageFile(file, imageParams)
                    .then((data) => {
                        imageFile = data.file
                        image = data.image

                        return getImageBase64(data.file)
                    })
                    .then((data) => {
                        imageBase64 = data

                        return uploadFileAction(imageFile)
                    })
                    .then((data) => {
                        return {
                            file: imageFile,
                            fileData: data,
                            image,
                            imageBase64,
                        }
                    })
                    .catch(() => {
                        return Promise.reject()
                    })
            }
        }

        return uploadFileAction(file)
            .then((data) => {
                return {
                    file,
                    fileData: data,
                    image: undefined,
                    imageBase64: undefined,
                }
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    function uploadFileAction(file: File) {
        return SimService.filesUpload(file)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    return {
        upload,
    }
}
